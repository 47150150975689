<template>
  <div class="header">
    <div class="sys-title">
      <!-- <el-image src="/logo.png" style="width: 60px; height: 60px">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image> -->
      <!-- <div class="title">疫情防控数据管理系统</div> -->
      <div class="title">{{ projectConfig.name }}</div>
    </div>
    <div class="header-right">
      <!-- <router-link to="/bigScreen" target="_black">
        <el-button size="small" style="margin-right: 30px">
          大数据展示
        </el-button>
      </router-link> -->
      <!-- <el-button size="small" style="margin-right: 30px" @click="openBigScreen">
        大数据展示
      </el-button> -->

      <el-dropdown @command="handleCommand" class="user_menu">
        <div class="el-dropdown-link user-msg">
          <!-- <el-avatar
            icon="el-icon-user-solid"
            :size="30"
            style="margin-right: 6px"
          ></el-avatar> -->
          {{ user.realName || "--" }}
          <!-- 用户名 -->
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="updatePassword">修改密码</el-dropdown-item>
          <el-dropdown-item command="loginOut">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
    >
      <div v-if="dialogVisible">
        <el-form
          ref="updatePasswordForm"
          :rules="updatePasswordRules"
          :model="updatePasswordForm"
          label-width="100px"
        >
          <el-form-item label="原密码" prop="oldPassword">
            <el-input
              v-model.trim="updatePasswordForm.oldPassword"
              show-password
              clearable
              type="password"
              placeholder="请输入原密码"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              v-model.trim="updatePasswordForm.password"
              show-password
              clearable
              type="password"
              placeholder="请输入新密码"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="surePassword">
            <el-input
              v-model.trim="updatePasswordForm.surePassword"
              show-password
              clearable
              type="password"
              placeholder="请输入确认密码"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitUpdate"
          :disabled="submiting"
          :loading="submiting"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { updatePassword } from "../api/login_v2";
import { projectConfig } from "@/commonConfig/project.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.updatePasswordForm.surePassword !== "") {
          this.$refs.updatePasswordForm.validateField("surePassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.updatePasswordForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      projectConfig,
      user: {},
      dialogVisible: false, //修改密码dialog
      updatePasswordForm: {}, //修改密码form
      submiting: false, //是否增在提交
      updatePasswordRules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          {
            min: 6,
            max: 50,
            message: "长度在 6 到 50 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 50,
            message: "长度在 6 到 50 个字符",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        surePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 6,
            max: 50,
            message: "长度在 6 到 50 个字符",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    // ...mapState(["userInfo"]),
  },
  methods: {
    handleCommand(command) {
      // console.log(command);
      if (command == "loginOut") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("userInfo");
        this.$store.commit("access_token", "");
        this.$router.push("/login");
      } else if (command == "updatePassword") {
        //重置密码按钮点击
        this.dialogVisible = true;
      }
    },
    //提交修改密码
    submitUpdate() {
      this.$refs["updatePasswordForm"].validate((valid) => {
        if (valid) {
          this.submiting = true;
          updatePassword(this.updatePasswordForm)
            .then((res) => {
              // console.log("****")
              // console.log(res);
              this.submiting = false;
              if (res.code == 1) {
                this.$message.success("密码修改成功,请重新登录");
                this.dialogVisible = false;
                this.handleCommand("loginOut");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((e) => {
              this.submiting = false;
              this.ruleForm.verification = "";
            });
        } else {
          return false;
        }
      });
    },

    // openBigScreen() {
    //   let routeUrl = this.$router.resolve({
    //     path: "/bigScreen",
    //   });
    //   window.open(routeUrl.href, "_blank");
    // },
  },
  mounted() {
    // console.log(this.userInfo, "aaaaaaaaaaaaa");
    let userInfo = sessionStorage.getItem("userInfo");
    this.user = JSON.parse(userInfo) || {};

    // console.log(this.user);
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 60px;
  width: 100%;
  min-width: 1190px;
  //background-color: rgb(50, 64, 87);
  // background-color: #FA436A;
  background-color: #144393;
  box-sizing: border-box;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .user_menu {
    cursor: pointer;
  }
  .sys-title {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: left;
    .title {
      line-height: 60px;
    }
  }
  .user-msg {
    display: flex;
    align-items: center;
  }
  .el-dropdown {
    color: #fff;
  }
  
}
</style>