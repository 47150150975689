<template>
  <div class="box-content">
    <div class="bread-crumb">
      <!-- <img class="bread-crumb-icon" src="@/assets/breadcrumbIcon.png" /> -->
      <!-- 面包屑 -->
      <el-breadcrumb class="bread-crumb-default" separator="/">
        <el-breadcrumb-item
          v-for="(item, index) in breadArr"
          :key="index"
          :to="item.path"
          >{{ item.title }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="use-background">
      <!-- <div class="use-content"> -->
      <slot></slot>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      BreadCrumb: "",
      breadArr: [],
    };
  },
  methods: {
    // 计算面包屑函数
    calcBread() {
      let arr = [];
      //全局通用
      arr.push({ path: "", title: "首页" });
      // console.log(this.$route);
      // this.$route.matched.forEach((item) => {
      //   if (item.meta.title) {
      //     if (item.meta.title instanceof Array) {
      //       for (let each of item.meta.title) {
      //         arr.push({
      //           path: item.path,
      //           title: each,
      //         });
      //       }
      //     } else {
      //       arr.push({
      //         path: item.path,
      //         title: item.meta.title,
      //       });
      //     }
      //   }
      // });
      arr.push(...(this.$route.meta.breadcrumb || []));
      this.breadArr = arr;
    },
  },
  // 生命周期
  created() {
    // 调用计算面包屑函数
    this.calcBread();
  },

  // 侦听器
  watch: {
    "$route.path"(newVal, oldVal) {
      // console.log(newVal, oldVal);
      // 调用计算面包屑函数
      this.calcBread();
    },
  },
};
</script>

<style lang="less" scoped>
.box-content {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}
.bread-crumb {
  // margin-bottom: 20px;
}

.bread-crumb-default {
  display: inline-block;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(82, 90, 110, 1);
  line-height: 12px;
  margin-left: 1px;
}

.use-background {
  min-width: 929px;
  width: 100%;
  padding: 10px;
  height: calc(100vh - 110px);
  box-sizing: border-box;
  overflow: auto;
  min-height: 360px;
  margin-top: 5px;
  background: rgba(255, 255, 255, 1);
}

// .use-content {
//   min-width: 929px;
//   height: 100%;
// }
.bread-crumb-icon {
  margin-right: 10px;
}
</style>