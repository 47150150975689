<template>
  <div class="menu-item">
    <template v-for="item in menuList">
      
      <el-menu-item
        v-if="item.children == undefined || item.children.length === 0"
        :index="`${item.menuUrl}`"
        :key="item.menuId"
      >
        <i :class="`${item.iconClass}`"></i>
        <span slot="title">{{ item.menuName }}</span>
      </el-menu-item>
      <el-submenu
        v-if="item.children && item.children.length > 0"
        :key="item.menuId"
        :index="`${item.menuId}`"
      >
        <template slot="title">
          
          <i :class="`${item.iconClass}`"></i>
          <span slot="title">{{ item.menuName }}</span>
        </template>
        <el-menu-item
          v-for="i in item.children"
          :key="i.menuId"
          :index="`${i.menuUrl}`"
        >
           <i :class="`${i.iconClass}`"></i>
          <span slot="title">{{ i.label }}</span>
        </el-menu-item>
      </el-submenu>
    </template>
  </div>
</template>

<script>
// import menu from "@/util/css/menu.css"

export default {
  name: "MenuItem",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.el-submenu .el-menu-item {
  min-width: 180px;
}
// /deep/
</style>