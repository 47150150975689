<template>
  <div class="home">
    <Header />
    <div class="home-content">
      <div class="aside">
        <el-menu
          :default-active="activeRoute"
          :unique-opened="true"
          :router="true"
          class="el-menu-vertical-demo"
        >
          <menu-item :menuList="menuData"></menu-item>
        </el-menu>
      </div>
      <div class="main">
        <contentBox>
          <router-view> </router-view>
        </contentBox>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "../../components/menuItem";
import Header from "../../components/header";
import ContentBox from "../../components/contentBox";
import getMenuDataBuild from "../../util/menuDataBuild";
import { mapState } from "vuex";
export default {
  components: {
    Header,
    MenuItem,
    ContentBox,
  },
  computed: {
    //...mapState[("userMenuData", "userInfo")],
    activeRoute() {
      return this.$route.path;
    },
  },
  methods: {
    //     const arrayData = [
    //   { id: 56, parentId: 62 },
    //   { id: 81, parentId: 80 },
    //   { id: 74, parentId: null },
    //   { id: 76, parentId: 80 },
    //   { id: 63, parentId: 62 },
    //   { id: 80, parentId: 86 },
    //   { id: 87, parentId: 86 },
    //   { id: 62, parentId: 74 },
    //   { id: 86, parentId: 74 },
    // ];
    // getIdMapping 之后得到下面数组，主要得到对应值的索引
    // {
    //   56: 0,
    //   62: 7,
    //   63: 4,
    //   74: 2,
    //   76: 3,
    //   80: 5,
    //   81: 1,
    //   86: 8,
    //   87: 6,
    // };
    
  },
  data() {
    return {
      menuData: [
        {
          label: "区域管理",
          menuId: "5",
          menuUrl: "",
          iconClass: "el-icon-menu",
          children: [
            {
              label: "电子围栏管理",
              menuId: "51",
              menuUrl: "/home/area/electronicFence",
              iconClass: "el-icon-s-order",
            },
            {
              label: "安全区域管理",
              menuId: "52",
              menuUrl: "/home/area/security",
              iconClass: "el-icon-s-order",
            },
            {
              label: "禁入区域管理",
              menuId: "53",
              menuUrl: "/home/area/noEntry",
              iconClass: "el-icon-s-order",
            },
          ],
        },
        {
          label: "权限管理",
          menuId: "6",
          menuUrl: "",
          iconClass: "el-icon-menu",
          children: [
            {
              label: "组织管理",
              menuId: "61",
              menuUrl: "/home/organizationManagerList",
              iconClass: "el-icon-s-order",
            },
            {
              label: "角色管理",
              menuId: "62",
              menuUrl: "/home/roleManagerList",
              iconClass: "el-icon-s-order",
            },
            {
              label: "用户管理",
              menuId: "63",
              menuUrl: "/home/userManagerList",
              iconClass: "el-icon-s-order",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    let menuData = JSON.parse(sessionStorage.getItem("loginUserMenu"));
    
    this.menuData = getMenuDataBuild(menuData).menu.children;
  },
};
</script>

<style lang="less" scoped>
.home {
  .home-content {
    background: RGBA(250, 249, 247, 1);
    width: 100%;
    min-width: 1260px;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    min-height: 500px;
  }
  .aside {
    width: 180px;
    background: #fff;
  }

  .el-menu {
    border-right: 0;
  }
  .main {
    // margin-top: 31px;
    // margin-left: 20px;
    // flex: 1;
    width: calc(100% - 180px);
    // background: #fc1;
  }
}
</style>